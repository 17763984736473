<template>
    <div>
        <CCard>
            <CCardHeader>
                <div>
                    <h4>Selección de Periodo</h4>
                </div>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol>
                        <CRow>
                            <CCol md="12">
                                <span slot="checkbox">
                                    Seleccionar último período en proceso
                                    <CSwitch 
                                    width="150px" class="mx-1 align-middle mb-2" color="success" shape="pill"
                                        variant="outline" 
                                        v-bind="{ labelOn: '\u2713', labelOff: '\u2715' }" 
                                        v-tippy="{
                                            placement: 'top',
                                            arrow: true,
                                            arrowType: 'round',
                                            animation: 'fade',
                                            theme: 'google',
                                            followCursor: true,
                                            content: 'Selección del Periodo Actual'
                                        }" 
                                        :disabled="selectPeriodoActual"
                                        :checked="selectPeriodoActual"
                                        @update:checked="selectPeriodoActual = !selectPeriodoActual; ultimoperiodo();" />
                                </span>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="3">
                                <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" 
                                    select-label="" selectedLabel="" deselect-label="" 
                                    label="nombreDocumento" 
                                    v-model="seltipodoc" 
                                    :options="tipodoc"
                                    placeholder="Tipo de Documento" 
                                    @select="seleccionarTipoDocumento">
                                </multiselect>
                            </CCol>
                            <CCol md="2" v-show="(seltipodoc != null) ? (seltipodoc.valor =='contratos') : false" >
                                mostrar por periodo 
                                <CSwitch
                                    width="150px"
                                    class="mx-1 align-middle mb-2"
                                    shape="pill"
                                    variant="outline"
                                    :checked="mostrarPeriodo"
                                    @update:checked="mostrarPeriodo = !mostrarPeriodo"
                                />
                            </CCol>
                            <CCol md="2" v-show="(seltipodoc != null) ? (seltipodoc.valor !='contratos') ? true : ((seltipodoc != null) ? (seltipodoc.valor =='contratos' && mostrarPeriodo == true) : false) : false">
                                <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" v-model="periodoSelected" deselect-label=""
                                    label="nombrePeriodo" placeholder="Año" select-label="" selectedLabel=""
                                    :custom-label="customNamePeriodo" :options="periodos" :searchable="true"
                                    :loading="isLoadingPeriodos" :disabled="selectPeriodoActual" @select="refreshperiodo">
                                </multiselect>
                            </CCol>
                            <CCol md="2" v-show="(seltipodoc != null) ? (seltipodoc.valor !='contratos') ? true : ((seltipodoc != null) ? (seltipodoc.valor =='contratos' && mostrarPeriodo == true) : false) : false">
                                <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" v-model="mesSelected" deselect-label=""
                                    label="nombreMes" placeholder="Mes" select-label="" selectedLabel="" :options="periodoSelected == null ? [] : periodoSelected.meses
                                        " :searchable="true" :loading="isLoadingMes" @select="refreshmes"
                                    :disabled="periodoSelected == null || selectPeriodoActual">
                                </multiselect>
                            </CCol>
                            <CCol md="3"> <!--Selector Empresas-->
                                <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" select-label="" selectedLabel=""
                                    deselect-label="" :hideSelected="false" :custom-label="nomcod" v-model="selempresa"
                                    :options="empresas" placeholder="Empresa" @select="obtenerTrabajadores"
                                    :disabled="seltipodoc == null" :loading="isLoadingEmpresas">
                                    
                                    <template slot="option" slot-scope="empresa">
                                        <CBadge class="m-1" style="font-size: 0.7rem; font-weight: normal"
                                            :color="empresa.option.estado == 1 ? 'info' : empresa.option.estado == 2 ? 'danger' : empresa.option.estado == 3 ? 'success' : 'secondary'"
                                            v-tippy="{
                                                placement: 'top',
                                                arrow: true,
                                                arrowType: 'round',
                                                animation: 'fade',
                                                theme: 'google',
                                                followCursor: true,
                                                content: empresa.option.estado == 1 ? 'En Revisón' : empresa.option.estado == 2 ? 'Rechazado ' + empresa.option.mensajeRespuesta + ' (' + empresa.option.fechaMensaje + ')' : empresa.option.estado == 3 ? 'Aceptado' : 'Periodo Sin Enviar'
                                            }">
                                            <CIcon v-if="empresa.option.estado == 1" class="iconMultiselectEstado" :content="$options.freeSet.cilArrowCircleRight" />
                                            <CIcon v-else-if="empresa.option.estado == 2" class="iconMultiselectEstado" :content="$options.freeSet.cilXCircle" />
                                            <CIcon v-else-if="empresa.option.estado == 3" class="iconMultiselectEstado" :content="$options.freeSet.cilCheckCircle" />
                                            <CIcon v-else class="iconMultiselectEstado" :content="$options.freeSet.cilBan" />
                                        </CBadge>
                                        ({{ empresa.option.codEmpresa }}) {{ empresa.option.nombreEmpresa }}
                                    </template>

                                    <template slot="singleLabel" slot-scope="empresa">
                                        <CBadge class="m-0"
                                            :color="empresa.option.estado == 1 ? 'info' : empresa.option.estado == 2 ? 'danger' : empresa.option.estado == 3 ? 'success' : 'secondary'"
                                            v-tippy="{
                                                placement: 'top',
                                                arrow: true,
                                                arrowType: 'round',
                                                animation: 'fade',
                                                theme: 'google',
                                                followCursor: true,
                                                content: empresa.option.estado == 1 ? 'En Revisón' : empresa.option.estado == 2 ? 'Rechazado ' + empresa.option.mensajeRespuesta + ' (' + empresa.option.fechaMensaje + ')' : empresa.option.estado == 3 ? 'Aceptado' : 'Periodo Sin Enviar'
                                            }">
                                            <CIcon v-if="empresa.option.estado == 1" class="iconMultiselectEstado" :content="$options.freeSet.cilArrowCircleRight" />
                                            <CIcon v-else-if="empresa.option.estado == 2" class="iconMultiselectEstado" :content="$options.freeSet.cilXCircle" />
                                            <CIcon v-else-if="empresa.option.estado == 3" class="iconMultiselectEstado" :content="$options.freeSet.cilCheckCircle" />
                                            <CIcon v-else class="iconMultiselectEstado" :content="$options.freeSet.cilBan" />
                                        </CBadge>
                                        ({{ empresa.option.codEmpresa }}) {{ empresa.option.nombreEmpresa }}
                                        
                                    </template>
                                </multiselect>

                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>
                <!--Opciones Documentos-->
                <CRow v-if="!this.isLoadingTrabajadores && (selempresa != null ? selempresa.hayDocumentosTrabajador : false) || this.visboton == 0">
                    <CCol md="2" v-if="(selempresa != null ? selempresa.hayTrabajadoresParaEnviar : false) || (selempresa != null ? selempresa.hayDocumentosTrabajador : false)">
                        <CDropdown
                            v-show="(selempresa != null ? selempresa.hayTrabajadoresParaEnviar : false) || (selempresa != null ? selempresa.hayDocumentosTrabajador : false)"
                            color="drpdwn-menu" size="sm">
                            <template #toggler-content>
                                <CIcon :content="$options.freeSet.cilApplications" :class="(isLoadingEnvioTodos) ? 'imgr' : ''" />
                                Opciones Dctos.
                            </template>
                            <div role="group">
                                <CDropdownItem v-if="selempresa != null ? selempresa.hayTrabajadoresParaEnviar : false"
                                    :disabled="isLoadingEnvioTodos" 
                                    v-tippy="{
                                        placement: 'top',
                                        arrow: true,
                                        arrowType: 'round',
                                        animation: 'fade',
                                        theme: 'google',
                                        followCursor: true,
                                        content: 'Enviar Todos los documentos a sus trabajadores'
                                    }"
                                    @click="enviaraTodos = true">
                                    {{ txtEnviarTodos }}
                                </CDropdownItem>
                                <CDropdownItem 
                                    v-show="selempresa != null ? selempresa.hayDocumentosTrabajador : false"
                                    @click="tripleDialog = true">
                                    Descargar Doctos.
                                </CDropdownItem>
                            </div>
                        </CDropdown>
                    </CCol>
                    <CCol md="2" v-show="!this.isLoadingTrabajadores && this.visboton == 0">
                        <CDropdown :disabled="isLoadingAceptaRechazo" color="drpdwn-menu" size="sm">
                            <template #toggler-content>
                                <CIcon :content="$options.freeSet.cilApplications" :class="(isLoadingAceptaRechazo) ? 'imgr' : ''" />
                                Aceptar o Rechazar Empresa
                            </template>
                            <div role="group">
                                <CDropdownItem v-show="this.visboton == 0" :disabled="estadosDocumentos.length > 0"
                                    @click="doubleDialog = true">
                                    Aceptar Empresa
                                </CDropdownItem>
                                <CDropdownItem @click="simpleDialog = true" v-show="this.visboton == 0">
                                    Rechazar Empresa
                                </CDropdownItem>
                            </div>
                        </CDropdown>
                    </CCol>
                    <CCol md="5" v-show="estadosDocumentos.length > 0">
                        <CAlert show color="warning">
                            <h4 class="alert-heading">
                                Ha rechazado {{ estadosDocumentos.length }} Documento{{ pluralizeDocumentos }}
                            </h4>
                            <p>
                                Estos documentos no han sido notificados aún, para que sean
                                notificados debe rechazar la empresa en cuestión le recomedamos
                                realizar esta acción
                            </p>
                            <hr />
                            <p class="mb-0">
                                En el caso de que necesite quitar uno a más documentos
                                rechazados ingrese al visor de documentos(icono de lupa en la
                                tabla) y quite el documento de los rechazados.
                            </p>
                        </CAlert>
                    </CCol>

                </CRow>
                <hr class="hr hr-blurry" />
                <CargandoInfo :mostrarCarga="isLoadingTrabajadores" :mensajeInfo="'Obteniendo Trabajadores...'" />
                <CRow v-show="!isLoadingTrabajadores">
                    <!--<CCol col="6">
                        <multiselect class="mt-2 mb-2" :id="selempresa != null ? selempresa : 'sinEmpresa'"
                            :disabled="selempresa == null" v-model="trabajadoresSelectedFiltro"
                            :custom-label="customTrabajador" select-label="" selectedLabel="" deselect-label=""
                            track-by="nombreTrabajador" placeholder="Buscar Trabajador" open-direction="bottom"
                            :options="trabajadoresFiltro" :multiple="true" :searchable="true" :internal-search="false"
                            @search-change="asyncFind" @select="filTrab" @remove="rmvTrabSel">
                            <template slot="afterList">
                                <div v-if="hasNextPage(selempresa)"
                                    v-observe-visibility="(isVisible, entry) => !isLoadingTrabajadoresFiltros ? reachedEndOfList(isVisible, entry, selempresa) : ''">
                                </div>
                            </template>
                            <span slot="noResult">No se han encontrado trabajadores con esos datos</span>
                        </multiselect>
                    </CCol>-->
                    <CCol col="4" v-show="selempresa != null">
                        <FiltroTrabajadores ref="filtroTrab" 
                            :empresaSelected="selempresa"
                            :tipoDocSelected="seltipodoc"
                            :mesSelected="mesSelected"
                            :anioSelected="periodoSelected"
                            @seleccionarTrab="filtrarTrabajadores($event)"
                         />
                    </CCol>
                </CRow>
                
                <CRow v-show="!isLoadingTrabajadores" v-if="selempresa != null && trabajadores.length>0">
                    <CCol>
                        <TblTrabDocumentos
                            :portContador="false"
                            :empresa="selempresa"
                            :periodoCerrado="selempresa.periodoCerrado"
                            :periodoSelected="periodoSelected"
                            :mesSelected="mesSelected"
                            :trabajadores="trabajadores"
                            :loading="false"
                            :paginacion="paginacion"
                            :tipoDocumentoSelected="seltipodoc"
                            :totalTrabajadoresDocumentos="selempresa.totalTrabajadoresDocumentos"
                            :totalTrabajadores="selempresa.totalTrabajadores"
                         />
                        
                    </CCol>
                </CRow>
            </CCardBody>
            <CCardFooter>
                
            </CCardFooter>
        </CCard>

        <ModalPdfView :prevue="prevue" :trabajadores="trabajadores" :trabajadorprev="trabajadorprev"
            :portalCliente="this" />

        <Modal v-model="tripleDialog" :title="'Descarga de ' + (seltipodoc == null ? '' : seltipodoc.nombreDocumento)">
            <h6>Descargar archivos cómo:</h6>
            <span>
                <input type="radio" id="Zip" value="zip" v-model="picked" />
                <label for="Zip" class="ml-1"> Zip</label>
                <br />
                <input type="radio" id="ArchivoUnico" value="merge" v-model="picked" />
                <label for="ArchivoUnico" class="ml-1"> Archivo Unico en PDF</label>
                <br />
                <div>
                    Su descarga
                    {{
                        selempresa != null
                        ? selempresa.trabajadoresFiltrados.length > 0
                            ? "de trabajadores seleccionados"
                            : ""
                        : ""
                    }}
                    comenzará al Aceptar.
                </div>
            </span>
            <br />
            <div>
                <CIcon v-show="isdownload" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                {{ auxi }}
            </div>
            <div v-show="generadoDocumentoDescarga">
                Si la descarga no inicia automaticamente descárguelo aquí
                <a id="documentoDescarga" class="ml-1" :href="linkDocumentoDescarga" target="_blank"
                    style="text-decoration: none; color: #0b7689" v-c-tooltip.hover="{
                        content: 'Descargar Documento',
                        placement: 'rigth',
                    }">
                    <CIcon :content="$options.freeSet.cilCloudDownload" />
                </a>
            </div>

            <CButton md="3" class="btnPrincipalVapp m-3 align-right" style="margin: 20%" id="descargar"
                @click="onClick(); tripleDialog = true;" :disabled="isdownload">Aceptar</CButton>
            <CButton md="3" class="btnPrincipalVapp m-3" style="margin: 20%"
                @click="closeModalDescarga(); tripleDialog = false;">Cancelar</CButton>
        </Modal>

        <Modal v-model="simpleDialog" :title="'Rechazo de ' + (seltipodoc == null ? '' : seltipodoc.nombreDocumento)">
            <div>
                <h6>Favor redacte la razon del rechazo para los documentos.</h6>
            </div>
            <div>
                <CTextarea placeholder="Razon..." horizontal rows="6" v-model="message" @keydown="check" />
                <p :class="{ help: true, 'is-danger': limit - message.length == 0 }">
                    {{ message.length }}/{{ limit }}
                </p>
            </div>
            <div>
                <h6>
                    ¿Está de acuerdo en rechazar la informacion presentada en los
                    documentos?
                </h6>
            </div>
            <CButton md="3" class="btnPrincipalVapp m-3 align-right" style="margin: 20%" @click="
                rechazar();
            simpleDialog = false;
            ">Aceptar</CButton>
            <CButton md="3" class="btnPrincipalVapp m-3" style="margin: 20%" @click="simpleDialog = false">Cancelar
            </CButton>
        </Modal>

        <Modal v-model="doubleDialog" :title="'Validación de ' +
            (seltipodoc == null ? '' : seltipodoc.nombreDocumento)
            ">
            <div>
                <h6>Verifique que todo está correcto antes de continuar.</h6>
            </div>
            <div>
                <h6>
                    Al hacer click en Aceptar, se le notificara al encargado acerca del
                    estado actual de los documentos.
                </h6>
            </div>
            <div>
                <h6>
                    ¿Está de acuerdo con la informacion presentada en los documentos?
                </h6>
            </div>
            <CButton md="3" class="btnPrincipalVapp m-3 align-right" style="margin: 20%"
                @click=" aceptar(); doubleDialog = false;">Aceptar</CButton>
            <CButton md="3" class="btnPrincipalVapp m-3" style="margin: 20%" @click="doubleDialog = false">Cancelar
            </CButton>
        </Modal>
    </div>
</template>
  
  
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  
<script>
import cons from "@/const";
import axios from "axios";
import Multiselect from "vue-multiselect";
import qs from "qs";
import * as util from '@/views/utils/utilsFunctions'
import apiUrls from '@/views/utils/apiUrls'

import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";

import { freeSet } from "@coreui/icons";
import CargandoInfo from '@/views/utils/CargandoInfo'

import ModalPdfView from "./componentes/ModalPdfView";
import TblTrabDocumentos from "./componentes/TablaTrabajadoresDocumentos";
import FiltroTrabajadores from "./componentes/FiltroTrabajadoresComponente";


import { format, formatDistance, formatRelative, subDays } from "date-fns";
import es from "date-fns/locale/es";
window.locale = "es";

export default {
    name: "RevisionDocumentosPCliente",
    freeSet,
    components: {
        Multiselect,
        Modal: VueModal,
        ModalPdfView,
        TblTrabDocumentos,
        CargandoInfo,
        FiltroTrabajadores
    },

    data() {
        return {
            tokenLogin: JSON.parse(this.$cookie.get("userLogginToken")).tokenUsuario,
            urlBase: cons.port + "://" + cons.ipServer,

            regMail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            mostrarPeriodo: false,

            paginacion: { pageSize: 10 },

            limit: 365,
            message: "",
            picked: "",
            link: "",
            isdownload: false,
            anno: null,
            mes: null,
            marcado: true,
            prev: "",

            desmodal: false,
            largo: 0,
            estadosDocumentos: [],

            isLoadingPeriodos: false,
            isLoadingMes: false,
            isLoadingTrabajadores: false,
            selectPeriodoActual: false,
            isLoadingEmpresas: false,
            isLoadingEnvioTodos: false,
            isLoadingEstadoEmpresa: false,
            isLoadingAceptaRechazo: false,

            txtEnviarTodos: "Enviar mail a todos",

            periodoSelected: null,
            mesSelected: null,
            selempresa: null,
            seltipodoc: null,
            estado: "",
            visboton: null,
            trabajadorprev: {},

            simpleDialog: false,
            doubleDialog: false,
            tripleDialog: false,
            prevue: false,

            empresas: [],
            periodos: [],
            tipodoc: [],
            trabajadores: [],

            descargas: [],
            auxi: "",

            rqstEmps: null,

            columns: [
                /*{
                  title: "Check",
                  scopedSlots: { customRender: "checkbox" },
                  width: "5%",
                },*/
                {
                    title: "Rut Trabajador",
                    dataIndex: "rutTrabajador",
                    scopedSlots: { customRender: "rut" },
                    key: "rutTrabajador",
                    width: "10%",
                },
                {
                    title: "Nombre",
                    dataIndex: "nombreTrabajador",
                    scopedSlots: { customRender: "nombre" },
                    width: "20%",
                },
                {
                    title: "Documento",
                    scopedSlots: { customRender: "documento" },
                    width: "15%",
                    align: "center",
                },
                {
                    title: "Última Generación",
                    scopedSlots: { customRender: "gen" },
                    width: "15%",
                    align: "center",
                },
                {
                    title: "Estado Documento",
                    scopedSlots: { customRender: "estadoDocumento" },
                    width: "10%",
                    align: "center",
                    ellipsis: true,
                },
                {
                    title: "Enviar Documento",
                    scopedSlots: { customRender: "envioCorreo" },
                    width: "20%",
                    align: "center",
                },
            ],

            generadoDocumentoDescarga: false,
            linkDocumentoDescarga: "",
            nombreDocumentoDescarga: "",

            columnasTrabajadores: [
                    { title: 'Rut', scopedSlots:{ customRender: 'rutTrabajador' }, key: 'rutTrabajador', width: '10%', ellipsis: true},// width: '10%'
                    { title: 'Trabajador', dataIndex: 'nombreTrabajador', key: 'nombreTrabajador',  width: '30%', ellipsis: true},// width: '20%'
                    //{ title: 'Última Generación', key: 'generacion', scopedSlots: { customRender: 'generacion' }, width: '20%', align: 'center', ellipsis: true},
                    //{ title: 'Estado Dcto.', key: 'estadoDocumento', scopedSlots: { customRender: 'estadoDocumento' }, width: '10%', align: 'center', ellipsis: false},
                    { slots: {title: 'tituloDoc'}, key: 'documento', scopedSlots: { customRender: 'documento' }, ellipsis: false, width: '20%', align: 'center'},// width: '10%'
                    {title: "Enviar Docto", scopedSlots: { customRender: 'envioCorreo' }, width: "40%", align: "center"}// width: '20%'
                ],
            columnasArchivosContratos: [
                { title: 'Tipo Dcto.', scopedSlots: { customRender: 'tipoDoc' }, width: '10%', align: 'left' ,ellipsis: false},
                { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, width: '20%', align: 'center', ellipsis: false},
                { title: 'Fecha Início Contrato', scopedSlots: { customRender: 'fechaContrato' }, width: '15%', align: 'left', ellipsis: false},
                { title: 'Fecha Vencimiento', scopedSlots: { customRender: 'fechaVencimiento' }, width: '15%', align: 'center', ellipsis: false},
                { title: 'Fecha Término', scopedSlots: { customRender: 'fechaTermino' }, width: '15%', align: 'center', ellipsis: false},
                { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, width: '20%', align: 'center', ellipsis: false},
            ],

            /* Filtro Trabajadores */
            trabajadoresFiltro: [],
            trabajadoresSelectedFiltro: null,
            isLoadingTrabajadoresFiltros: false,
        };
    },
    computed: {
        pluralizeDocumentos() {
            return this.estadosDocumentos.length < 1 ? "s" : "";
        }
    },
    beforeMount() {
        this.obtenerDocumento();
        this.obtenerPeriodos();
    },

    methods: {
        seleccionarTipoDocumento: function(tipoDoc){
            if(tipoDoc.valor =='contratos'){
                this.obtenerEmpresa(tipoDoc)
            }else{
                if(this.periodoSelected != null) 
                    this.refreshperiodo(this.periodoSelected)
                else{
                    this.empresas = []
                    this.empresasFiltradas = []
                }
            }
        },
        refreshperiodo: function (periodoSelected) {
            this.periodoSelected = periodoSelected;
            this.empresas = []
            if(this.mesSelected != null){
                let encontrado = false
                this.periodoSelected.meses.forEach(mes =>{
                    if(mes.valor == this.mesSelected.valor){
                        encontrado = true
                        this.refreshmes(mes)
                    }
                })
                if(!encontrado){
                    this.mostrarNotificaciones(2, `El año seleccionado no tiene el mes ${this.mesSelected.nombreMes}, se seleccionara el último disponible`)
                    this.refreshmes(this.periodoSelected.meses[this.periodoSelected.meses.length - 1])
                }
            }

        },

        refreshmes: function (mesSelected) {
            this.mesSelected = mesSelected;
            if(this.periodoSelected != null){
            //if(this.selempresa == null){
                //this.isLoadingEmpresas = true
                //this.isLoadingTrabajadores = true
                this.obtenerEmpresa(this.seltipodoc);
            }
            //}

            /*if (
            mesSelected != null &&
            this.periodoSelected != null &&
            this.seltipodoc != null &&
            this.selempresa != null
            ) {
                this.obtenerTrabajadores(this.selempresa);
            }*/
        },
        obtenerEmpresa: function (tipoDoc) {
            //if (this.periodoSelected != null || this.mesSelected != null) {
                this.isLoadingEmpresas = true;
                let url = `${this.urlBase}${apiUrls.general.getEmpresas}`
                
                if (this.rqstEmps != null) {
                    this.rqstEmps.cancel();
                    this.rqstEmps.msg = "cancelado";
                    this.rqstEmps = null;
                }
                const axiosSource = axios.CancelToken.source();
                this.rqstEmps = { cancel: axiosSource.cancel, msg: "Cargando..." };
                axios({
                    method: "POST",
                    url: url,
                    cancelToken: axiosSource.token,
                    "data": {
                        "tipoBD": "REMU",
                        "filtrarPorPeriodo": this.mostrarPeriodo,
                        "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                        "mesPeriodo":(this.mesSelected != null) ? this.mesSelected.valor : null,
                        "tipoDocumento": (tipoDoc != null) ? tipoDoc.valor : null,
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                })
                    .then(
                        (result) => {
                            this.empresas = result.data.empresas;
                            var BreakException = {};

                            if (this.selempresa != null) {
                                try {
                                    result.data.empresas.forEach((empresa) => {
                                        if (empresa.idEmpresa == this.selempresa.idEmpresa) {
                                            this.selempresa = empresa;
                                            this.paginacion = this.selempresa.paginacion;
                                            throw BreakException;
                                        }
                                    });
                                } catch (e) {
                                    if (e !== BreakException) throw e;
                                }
                                this.trabajadoresFiltro = [];
                                this.obtenerTrabajadores(this.selempresa);
                            } else {
                                if (this.selectPeriodoActual) {
                                    this.selempresa = this.empresas[0];
                                    this.paginacion = this.selempresa.paginacion;
                                    this.trabajadoresFiltro = [];
                                    this.obtenerTrabajadores(this.selempresa);
                                }
                            }


                        },
                        (error) => { console.log(error.response)}
                    )
                    .finally(() => {
                        this.selectPeriodoActual = false;
                        this.isLoadingEmpresas = false;
                        this.rqstEmp = null;
                    });
            //}
        },
        
        getTrabajadores: function (empresa) {

            this.isLoadingTrabajadoresFiltros = true;
            
            let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + empresa.idEmpresa}/${(this.seltipodoc != null) ? this.seltipodoc.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`


            if (empresa.rqstTrabs != null) {
                empresa.rqstTrabs.cancel();
                empresa.rqstTrabs.msg = "cancelado";
                empresa.rqstTrabs = null;
            }
            const axiosSource = axios.CancelToken.source();
            empresa.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };

            axios({
                method: "POST",
                url: url,
                cancelToken: axiosSource.token,
                data: {
                    paginacionTrabajadores: {
                        strFiltro: empresa.strFiltroTrabajadores,
                        actual: empresa.currentPage,
                        tamanioPagina: 10,
                    },
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (result) => {
                        empresa.totalPages = Number((result.data.totalTrabajadores / 10).toFixed(0));
                        if (this.trabajadoresFiltro.length == 1 && this.trabajadoresFiltro[0].rutTrabajador == "0") {
                            this.trabajadoresFiltro = [];
                        }

                        this.trabajadoresFiltro = this.trabajadoresFiltro.concat(result.data.trabajadores);
                        empresa.currentPage++;
                    },
                    (error) => {
                    }
                )
                .finally(() => {
                    this.isLoadingTrabajadoresFiltros = false;
                    empresa.rqstTrabs = null;
                });
        },
        enviaraTodos: function () {
            this.isLoadingEnvioTodos = true;
            this.txtEnviarTodos = "enviando";
            var url =
                cons.port +
                "://" +
                cons.ipServer +
                "/" +
                "enviar_documento_trabajador/";
            var trabajadores = [];
            if (
                this.trabajadoresSelectedFiltro != null &&
                this.trabajadoresSelectedFiltro.length > 0
            ) {
                this.trabajadoresSelectedFiltro.forEach((trabajador) => {
                    trabajadores.push({ rutTrabajador: trabajador.rutTrabajador });
                });
            }
            axios({
                method: "POST",
                url: url,
                data: {
                    trabajadoresEnviar: {
                        enviarTodos: true,
                        idEmpresa: this.selempresa.idEmpresa,
                        anioPeriodo: this.periodoSelected.valor,
                        mesPeriodo: this.mesSelected.valor,
                        tipoDoc: this.seltipodoc.valor,
                        trabajadores: trabajadores,
                    },
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (result) => {
                        this.$notification.success(result.data.mensaje, {
                            timer: 10,
                            position: "bottomRight",
                        });
                    },
                    (error) => {
                        this.$notification.error(result.data.mensaje, {
                            timer: 10,
                            position: "bottomRight",
                        });
                    }
                )
                .finally(() => {
                    this.txtEnviarTodos = "Enviar mail a todos";
                    this.isLoadingEnvioTodos = false;
                    this.obtenerTrabajadores(this.selempresa);
                });
        },
        enviarDocumentoTrabajador: function (trabajador) {
            trabajador.documento.isLoadiongEnvio = true;
            var url =
                cons.port +
                "://" +
                cons.ipServer +
                "/" +
                "enviar_documento_trabajador/";

            axios({
                method: "POST",
                url: url,
                data: {
                    trabajadoresEnviar: {
                        enviarTodos: false,
                        idEmpresa: this.selempresa.idEmpresa,
                        anioPeriodo: this.periodoSelected.valor,
                        mesPeriodo: this.mesSelected.valor,
                        tipoDoc: this.seltipodoc.valor,
                        trabajadores: [{ rutTrabajador: trabajador.rutTrabajador }],
                    },
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (result) => {
                        this.$notification.success(result.data.mensaje, {
                            timer: 10,
                            position: "bottomRight",
                        });
                    },
                    (error) => {
                        this.$notification.error(result.data.mensaje, {
                            timer: 10,
                            position: "bottomRight",
                        });
                    }
                )
                .finally(() => {
                    trabajador.documento.isLoadiongEnvio = false;
                    this.obtenerTrabajadores(this.selempresa);
                });
        },

        obtenerDocumento: function () {
            var url = cons.port + "://" + cons.ipServer + "/get_tipo_documentos";
            axios({
                method: "GET",
                url: url,
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
            .then(
                (result) => {
                    this.tipodoc = result.data;
                    if (this.tipodoc.length == 1) {
                        this.seltipodoc = this.tipodoc[0];
                        this.obtenerEmpresa(this.seltipodoc);
                    }
                },
                (error) => { }
            )
            .finally(() => { });
        },
        /*handleTableChange: function (pagination, filters, sorter, filasActuales) {
            pagination.empresa.paginacion = pagination;
            this.paginacion = pagination;
            //pagination.empresa.paginacion = pagination
            this.obtenerTrabajadores(pagination.empresa);
        },*/

        filtrarTrabajadores: function(evento){
            this.selempresa.trabajadoresFiltrados = evento
            this.selempresa.paginacion.current = 1;

            this.obtenerTrabajadores(this.selempresa)
        },

        obtenerTrabajadores: function (selempresa) {

            this.getTrabajadores(selempresa);
            this.selempresa = selempresa;
            this.isLoadingTrabajadores = true;
            this.estadosDocumentos = [];

            let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.selempresa.idEmpresa}/${(this.seltipodoc != null) ? this.seltipodoc.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`

            //let url = this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.selempresa.idEmpresa + "/" + this.seltipodoc.valor + "/" + this.mesSelected.valor + "/" + this.periodoSelected.valor;
            

            axios({
                method: "POST",
                url: url,
                data: {
                    paginacionTrabajadores: {
                        actual: selempresa.paginacion.current,
                        tamanioPagina: selempresa.paginacion.pageSize,
                        filtrosTrabajadores: selempresa.trabajadoresFiltrados,
                    },
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (result) => {
                        this.selempresa.hayTrabajadoresParaEnviar =
                            result.data.hayTrabajadoresParaEnviar;

                        if (result.data.totalDocumentosTrab > 0) {
                            this.selempresa.hayDocumentosTrabajador = true;
                        }

                        this.trabajadores = result.data.trabajadores;

                        if (this.selempresa.totalTrabajadoresDocumentos > 0) {
                            this.selempresa.hayDocumentosTrabajador = true;
                        }

                        const pagination = { ...this.paginacion };
                        pagination.total = result.data.totalTrabajadores;
                        pagination.empresa = this.selempresa;
                        this.paginacion = pagination;
                        this.selempresa.paginacion = pagination;
                        
                        if(this.selempresa.estado == 1 || this.selempresa.estado ==3){
                            this.selempresa.totalTrabajadoresDocumentos = result.data.totalDocumentosTrab
                            this.selempresa.totalTrabajadores = result.data.totalTrabajadores
                        }

                        this.$refs.filtroTrab.getTrabajadores()
                        /*this.trabajadores.forEach(trab =>{
                            if(trab.documento.hayDocumento){
                                this.selempresa.hayDocumentosTrabajador = true
                            }
                        })*/
                    },
                    (error) => { }
                )
                .finally(() => {
                    this.isLoadingTrabajadores = false;
                    this.validarestado();
                    this.visualbotones();
                });
        },

        customNamePeriodo(periodo) {
            return `${periodo.nombrePeriodo}`;
        },

        nomcod(empresa) {
            return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
        },

        obtenerPeriodos: function () {
            var url = cons.port + "://" + cons.ipServer + "/get_periodos/";

            axios({
                method: "GET",
                url: url,
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (result) => {
                        this.periodos = result.data;
                        if (this.periodos.length == 1) {
                        }
                    },
                    (error) => { }
                )
                .finally(() => { });
        },

        check: function (evt) {
            if (this.message.length >= this.limit) {
                if (evt.keyCode >= 48 && evt.keyCode <= 90) {
                    evt.preventDefault();
                    return;
                }
            }
        },

        closeModalDescarga: function () {
            this.generadoDocumentoDescarga = false;
            this.linkDocumentoDescarga = "";
            this.nombreDocumentoDescarga = "";
            this.auxi = "";
        },

        onClick() {
            this.isdownload = true;
            this.auxi = "Estamos Generando el archivo, espere por favor...";
            //this.auxi = "Generando Enlace de Descarga";
            var trabajadores = [];
            if (
                this.trabajadoresSelectedFiltro != null &&
                this.trabajadoresSelectedFiltro.length > 0
            ) {
                this.trabajadoresSelectedFiltro.forEach((trabajador) => {
                    trabajadores.push({ rutTrabajador: trabajador.rutTrabajador });
                });
            }

            axios({
                url:
                    cons.port + "://" + cons.ipServer + "/descargar_documentos/",
                    /*+
                    this.picked +
                    "/" +
                    this.selempresa.codEmpresa +
                    "/" +
                    this.seltipodoc.valor +
                    "/" +
                    this.selempresa.idEmpresa +
                    "/REMU/" +
                    this.mesSelected.valor +
                    "/" +
                    this.periodoSelected.valor,*/
                method: "POST",
                data: {
                    "tipoBd": "REMU",
                    "codEmpresa": this.selempresa.codEmpresa,
                    "idEmpresa": this.selempresa.idEmpresa,
                    "tipoDescarga": this.picked,
                    "filtrarPorPeriodo": this.mostrarPeriodo,
                    "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                    "mesPeriodo":(this.mesSelected != null) ? this.mesSelected.valor : null,
                    "tipoDocumento": (this.seltipodoc != null) ? this.seltipodoc.valor : null,
                    infoTrabajadores: {
                        trabajadoresSelected: [],
                        trabajadores: trabajadores,
                    },
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (response) => {
                        this.generadoDocumentoDescarga = true;
                        this.linkDocumentoDescarga = response.data.archivo.link;
                        this.nombreDocumentoDescarga = response.data.archivo.nombre;

                        this.auxi = "Generando link de descarga un mnomento...";
                        /*const link = document.createElement("a");
                        link.href = response.data.link;
                        link.download = response.data.nombre;
                        link.setAttribute('target', '_blank')*/
                        console.log(response.data)
                        const link = document.createElement('a');
                        link.href = response.data.archivo.link;
                        link.download = response.data.archivo.nombre; // Puedes cambiar el nombre del archivo
                        link.style.display = 'none';

                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);

                        /*var elemento = document.getElementById("documentoDescarga");
                        elemento.setAttribute("href", response.data.archivo.link);
                        elemento.click();*/

                        this.auxi = "Pronto Comenzará su Descarga";
                        //link.click()

                        /*link.dispatchEvent(
                            new MouseEvent('click', {
                                bubbles: true,
                                cancelable: true,
                                view: window
                            })
                        );*/

                        if (this.picked == "merge") {
                            this.$notification.success(
                                "La Descarga del SuperDocumento comenzará pronto...",
                                { timer: 10, position: "bottomRight" }
                            );
                        } else {
                            this.$notification.success(
                                "La Descarga de Archivos Comprimidos comenzará pronto...",
                                { timer: 10, position: "bottomRight" }
                            );
                        }
                        /*var x = 1;
                        this.auxi = "Generando link de descarga un mnomento...";
            
                        var fileLink = document.createElement("a");
                        fileLink.href = response.data.link;
            
                        if (this.picked == "merge") {
                          fileLink.setAttribute("download", response.data.nombre);
                          x = 0;
                        }
                        if (this.picked == "zip") {
                          fileLink.setAttribute("download", response.data.nombre);
                          x = 1;
                        }
                        fileLink.setAttribute('target', '_blank');
                        document.body.appendChild(fileLink);
                        this.auxi = "Pronto Comenzara su Descarga";
                        fileLink.click();
                        document.body.removeChild(fileLink);
            
                        if (x == 0) {
                          this.$notification.success(
                            "La Descarga del SuperDocumento comenzará pronto...",
                            { timer: 10, position: "bottomRight" }
                          );
                        } else {
                          this.$notification.success(
                            "La Descarga de Archivos Comprimidos comenzará pronto...",
                            { timer: 10, position: "bottomRight" }
                          );
                        }
                        this.isdownload = false;
                        */
                    },
                    (error) => {
                        this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                        
                    }
                )
                .finally(() => {
                    this.isdownload = false;
                    //this.tripleDialog = false;
                });
        },

        

        aceptar: function () {
            this.isLoadingAceptaRechazo = true
            //let url = cons.port +"://" + cons.ipServer + "/" + "aceptar_documentos/" + this.selempresa.idEmpresa + "/" + (this.mesSelected != null) ? this.mesSelected.valor : null + "/" + (this.periodoSelected != null) ? this.periodoSelected.valor : null + "/" + (this.seltipodoc != null) ? this.seltipodoc.valor : null
            let url = `${this.urlBase + "/aceptar_documentos/" + this.selempresa.idEmpresa}/${(this.mesSelected != null) ? this.mesSelected.valor : null}/${(this.periodoSelected != null) ? this.periodoSelected.valor : null}/${(this.seltipodoc != null) ? this.seltipodoc.valor : null}`

            axios({
                url: url,
                method: "POST",
                "data": {
                    "filtrarPorPeriodo": this.mostrarPeriodo,
                    "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                    "mesPeriodo":(this.mesSelected != null) ? this.mesSelected.valor : null,
                    "tipoDocumento": (this.seltipodoc != null) ? this.seltipodoc.valor : null,
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (result) => {
                        //this.selempresa.estado = result.data.estadoEmpresa;
                        this.$notification.success("La empresa ha sido Aceptada con exito", { timer: 10, position: "bottomRight" });
                        this.isLoadingAceptaRechazo = false
                        this.obtenerEmpresa(this.seltipodoc);
                    },
                    (error) => {
                        this.$notification.error(error.response.data.mensaje, {
                            timer: 10,
                            position: "bottomRight",
                        });
                        this.isLoadingAceptaRechazo = false
                        /*if (error.response.data) {
            
                          if (this.selempresa.estado == 0 || this.selempresa.estado == 1) {
                            if (this.selempresa.estado == 0) {
                              this.$notification.error(
                                "La Empresa se encuentra SIN REVISAR",
                                { timer: 10, position: "bottomRight" }
                              );
                            } else {
                              this.$notification.error(
                                "La Empresa se encuentra EN REVISIÓN",
                                { timer: 10, position: "bottomRight" }
                              );
                            }
                          } else if (this.selempresa.estado == 2) {
                            this.$notification.error("La Empresa se encuentra RECHAZADA", {
                              timer: 10,
                              position: "bottomRight",
                            });
                          } else {
                            this.$notification.error("La Empresa se encuentra ACEPTADA", {
                              timer: 10,
                              position: "bottomRight",
                            });
                          }
                        }*/
                    }
                )
                .finally(() => {
                    this.validarestado();
                });
        },

        validarestado: function () {
            if (this.selempresa.estado == 0) {
                this.estado = "Sin Revisar";
                this.visualbotones();
            }
            if (this.selempresa.estado == 1) {
                this.estado = "En Revisión";
                this.visualbotones();
            }
            if (this.selempresa.estado == 2) {
                this.estado = "Rechazada";
                this.visualbotones();
            }
            if (this.selempresa.estado == 3) {
                this.estado = "Aceptada";
                this.visualbotones();
            }
        },
        rechazar: function () {
            const datos = {
                mensajeRechazo: this.message,
                documentosRechazados: this.estadosDocumentos,
            };

            this.isLoadingAceptaRechazo = true
            let url = `${this.urlBase + "/rechazar_documentos/" + this.selempresa.idEmpresa}/${(this.mesSelected != null) ? this.mesSelected.valor : null}/${(this.periodoSelected != null) ? this.periodoSelected.valor : null}/${(this.seltipodoc != null) ? this.seltipodoc.valor : null}`

            axios({
                url:url,
                method: "POST",
                data: { 
                    datosRechazo: datos,
                    "filtrarPorPeriodo": this.mostrarPeriodo,
                    "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                    "mesPeriodo":(this.mesSelected != null) ? this.mesSelected.valor : null,
                    "tipoDocumento": (this.seltipodoc != null) ? this.seltipodoc.valor : null,
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            })
                .then(
                    (result) => {
                        this.selempresa.estado = result.data.estadoEmpresa;
                        this.$notification.success(
                            "La Empresa ha sido Rechazada con exito",
                            { timer: 10, position: "bottomRight" }
                        );
                        this.isLoadingAceptaRechazo = false
                        this.obtenerEmpresa(this.seltipodoc);
                    },
                    (error) => {
                        this.$notification.error(error.response.data.mensaje, {
                            timer: 10,
                            position: "bottomRight",
                        });
                        this.isLoadingAceptaRechazo = false
                    }
                )
                .finally(() => {
                    this.validarestado();
                });
        },
        visualbotones: function () {
            if (
                this.estado == "Sin Revisar" ||
                this.estado == "Rechazada" ||
                this.estado == "Aceptada"
            ) {
                this.visboton = 1;
            } else {
                this.visboton = 0;
            }
        },


        ultimoperiodo: function () {
            var anioActual = new Date().getFullYear();
            var mesActual = new Date().getMonth(); //se obtiene el anterior

            if (mesActual == 0) {
                mesActual = 12;
                anioActual = anioActual - 1;
            }

            if (this.selectPeriodoActual) {
                for (var periodo in this.periodos) {
                    if (this.periodos[periodo].valor == anioActual) {
                        this.periodoSelected = this.periodos[periodo];
                        var mesEncontrado = false;

                        for (var mes in this.periodoSelected.meses) {
                            if (this.periodoSelected.meses[mes].valor == mesActual) {
                                this.mesSelected = this.periodoSelected.meses[mes];
                                mesEncontrado = true;
                                break;
                            }
                        }
                        if (mesEncontrado) break;
                    }
                }
                this.seltipodoc = this.tipodoc[0];
                this.obtenerEmpresa(this.seltipodoc);
            }
        },
        formatFechaDocumento: function (fechaDoc) {
            //return format(new Date(fechaDoc), 'EEE d LLL/yy H:mm:ss', {locale: es})//'dd/MM/yyyy' 'EEE d LLL/yy H:mm:ss'

            if (fechaDoc != null) {
                return formatDistance(new Date(fechaDoc), new Date(), {
                    addSuffix: true,
                    locale: es,
                }); //.isSame(moment().clone().startOf('day'), 'd')
            } else {
                return "Nunca";
            }
        },

        abrirVisor: function (trabajador) {
            this.prevue = true;
            this.trabajadorprev = trabajador;
        },

        primerdisp: function () {
            this.obtenerDocumento();
            //this.seltipodoc = this.tipodoc[0];
            //this.obtenerEmpresa(this.seltipodoc);
        },

        /* filtroTrabajadores sin usar */
        
        getTrabajadoresFiltrados: function (empresa) {
            if (empresa.trabajadoresFiltrados.length > 0) {
                this.txtEnviarTodos = "Enviar mail a seleccionados";
            } else {
                this.txtEnviarTodos = "Enviar mail a todos";
            }

            this.isLoadingTrabajadoresFiltros = true;
            var url = this.urlBase + apiUrls.general.getTrabajadoresEmpresa +
                empresa.idEmpresa +
                "/" +
                this.seltipodoc.valor +
                "/" +
                this.mesSelected.valor +
                "/" +
                this.periodoSelected.valor;

            if (empresa.rqstTrabs != null) {
                empresa.rqstTrabs.cancel();
                empresa.rqstTrabs.msg = "cancelado";
                empresa.rqstTrabs = null;
                this.isLoadingTrabajadoresFiltros = false
            }
            const axiosSource = axios.CancelToken.source();
            empresa.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };

            axios({
                method: "POST",
                url: url,
                cancelToken: axiosSource.token,
                data: {
                    paginacionTrabajadores: {
                        strFiltro: empresa.strFiltroTrabajadores,
                        actual: empresa.currentPage,
                        tamanioPagina: 10,
                    },
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
            }).then((result) => {
                        empresa.totalPages = Number((result.data.totalTrabajadores / 10).toFixed(0));

                        this.trabajadoresFiltro = result.data.trabajadores;
                        empresa.currentPage++;

                    },
                    (error) => {
                    }
                )
                .finally(() => {
                    this.isLoadingTrabajadoresFiltros = false;
                    empresa.rqstTrabs = null;
                });
        },
        filTrab: function (trab, empresa) {
            empresa.trabajadoresFiltrados.push(trab);
            empresa.paginacion.current = 1;
            this.obtenerTrabajadores(empresa);
        },
        reachedEndOfList(reached, entry, empresa) {
            if (empresa != null)
                if (reached) {
                    if (this.hasNextPage(empresa)) this.getTrabajadores(empresa);
                }
        },
        hasNextPage(empresa) {
            if (empresa != null) return empresa.currentPage + 1 <= empresa.totalPages;
        },        
        asyncFind: function (query, empresa) {
            //recibe el id,por lo que se seteo el id por la empresa para poder pasar el valor

            empresa.strFiltroTrabajadores = query;
            empresa.currentPage = 1;
            this.trabajadoresFiltro = [
                {
                    rutTrabajador: "0",
                    nombreTrabajador: "Buscando...",
                    documento: { hayDocumento: false },
                    $isDisabled: true,
                },
            ];
            this.getTrabajadoresFiltrados(empresa);
        },
        rmvTrabSel: function (trab, empresa) {
            for (var indx = 0; indx <= empresa.trabajadoresFiltrados.length; indx++) {
                if (
                    empresa.trabajadoresFiltrados[indx].rutTrabajador ==
                    trab.rutTrabajador
                ) {
                    empresa.trabajadoresFiltrados.splice(indx, 1);
                    break;
                }
            }
            if (this.mesSelected != null && this.periodoSelected != null)
                this.obtenerTrabajadores(empresa);
        },
        customTrabajador: function (trabajador) {
            if (trabajador.rutTrabajador == "0") {
                return "Buscando...";
            } else {
                let sinDoc = trabajador.documentos > 0 ? "" : "(Sin Documento)"
                return `${util.formatearRut(trabajador.rutTrabajador)} – ${trabajador.nombreTrabajador} ${sinDoc}`;
            }
        },
    },
};
</script>
  
<style scoped>

.tippy-tooltip.google-theme{
  background-color:#29235C;
  font-weight: normal;
}
.tippy-tooltip.google-theme .tippy-backdrop {
  
  background-color:#29235C;
}
.tippy-content{
  background-color: transparent;
}

.form-group {
    margin-top: 1rem;
    margin-bottom: 0;
}

.form-row {
    width: 80%;
}
</style>