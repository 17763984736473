<template>
  <Modal
    v-model="prevue"
    :title="'Vista previa del documento'"
    :modal-style="{ 'min-width': '300px', 'max-width': '1140px' }"
    @close="cerrarModal"
  >
    <!--
      @after-open="getTrabajadores"
      <multiselect
      class="m-0 d-inline-block mb-2 float-left"
      select-label=""
      selectedLabel=""
      deselect-label=""
      :custom-label="customTrabajador"
      v-model="trabajadorprev"
      :options="trabajadores"
      placeholder="Lista de Trabajadores"
      @select="vistaprevia"
    >
    </multiselect>-->
    <multiselect 
      v-model="trabajadorprev" 
      :custom-label="customTrabajador" 
      select-label=""
      selectedLabel=""
      deselect-label=""
      track-by="nombreTrabajador" 
      placeholder="Buscar Trabajador" 
      open-direction="bottom" 
      :options="trabajadoresModal" 
      :multiple="false" 
      :searchable="true"
      :loading="isLoadingTrabajadores" 
      :internal-search="false" 
      @search-change="asyncFind"
      @select="seleccionarTrab">
      <template slot="afterList">
        <div v-observe-visibility="(isVisible, entry) =>!isLoadingTrabajadores?reachedEndOfList(isVisible):''" v-if="hasNextPage" />
      </template>
        <!--<template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.name }}</span><span class="custom__remove" @click="remove(option)">❌</span></span></template>
        <template slot="clear" slot-scope="props">
          <div class="multiselect__clear" v-if="selectedCountries.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
        </template><span slot="noResult">Oops! No elements found. Consider changing the search query.</span>-->
    </multiselect>
    <CRow sm="12" md="12" lg="12">
      <CCol sm="6" md="6" lg="6" align="center">
        <CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="descargamodal"
          content="Descargar Documento"
            v-tippy="{ 
                placement: 'top',
                arrow: true, 
                arrowType: 'round', 
                animation:'fade',
                theme: 'google',
                followCursor : true,
            }"
          >
          <CIcon
            :content="$options.freeSet.cilCloudDownload"
            
          />
        </CButton>
        
        <!--<CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="(derizq = 0), anteriorsiguiente(derizq)"
          :disabled="haydoc == false"
          ><CIcon
            :content="$options.freeSet.cilArrowThickFromRight"
            v-c-tooltip.hover="{
              content: 'Anterior Trabajador/a',
              placement: 'over',
            }"
        /></CButton>
        <CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="(derizq = 1), anteriorsiguiente(derizq)"
          :disabled="haydocsig == false"
          ><CIcon
            :content="$options.freeSet.cilArrowThickFromLeft"
            v-c-tooltip.hover="{
              content: 'Siguiente Trabajador/a',
              placement: 'over',
            }"
        /></CButton>-->
      </CCol>
      <CCol sm="6" md="6" lg="6" align="center" v-if="portalCliente.selempresa != null" v-show="portalCliente.selempresa.estado != '3' && portalCliente.selempresa.estado != '2'">
        <CButton
          v-if="trabajadorprev != null && trabajadorprev.documento != null"
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="rechazarDocumentos"
          :disabled="(trabajadorprev != null)?(trabajadorprev.documento != null ? ((trabajadorprev.documento.hayDocumento)?trabajadorprev.documento.estadoDocumento == 2 || trabajadorprev.documento.estadoDocumento == 0:false) : false) : false"
        >Rechazar Documento</CButton>
        
        <CButton
         v-if="trabajadorprev.documento != null"
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="quitarRechazoDocumento"
          v-show="trabajadorprev.documento.estadoDocumento == 2"
          >Quitar Rechazado</CButton>
        </CCol>
    </CRow>
    <iframe
      v-if="trabajadorprev != null && trabajadorprev.documento != null"
      :src="trabajadorprev.documento.viewDocumento.replace('view', 'preview')"
      width="100%"
      height="400"
      allow="autoplay"
    ></iframe>
    <CRow>
      <CCol sm="12" md="12" lg="12" align="right">
        <CButton
          md="3"
          class="btnPrincipalVapp m-3"
          style="margin: 20%"
          @click="cerrarModal"
          >Cerrar</CButton
        ></CCol
      ></CRow
    >
  </Modal>
  
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import cons from "@/const";
import Multiselect from "vue-multiselect";
import axios from "axios";

import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";

import { freeSet } from "@coreui/icons";

export default {
  name: "ModalPdfView",
  freeSet,
  props: {
    prevue: Boolean,
    trabajadores: Array,
    trabajadorprev: Object,
    portalCliente: Object,
  },
  components: {
    Multiselect,
    Modal: VueModal,
  },
  data() {
    return {
      tokenLogin: JSON.parse(this.$cookie.get("userLogginToken")).tokenUsuario,

      contador: 0,
      derizq: null,
      estado: null,
      haydoc: true,
      haydocsig: true,

      isLoadingTrabajadores: false,

      trabajadoresModal: [],

      currentPage: 1,
      totalPages: 20,
      
      strFiltroTrabajadores: "",

      rqstTrabs: null,

      

    };
  },
  created: function () {
  },
  beforeMount() {
    let cookieUsername = this.$cookie.get("userLogginToken");
    if (cookieUsername === null || cookieUsername === "") {
      this.$router.push("/pages/login");
    }
    //this.trabajadorprev = this.trabajador;
    
  },
  methods: {
    reachedEndOfList(reached) {
      if (reached) {
        if(!this.isLoadingTrabajadores){
          if(this.hasNextPage())
            this.getTrabajadores("reachedEndOfList")
        }
      }
    },
    hasNextPage() { return this.currentPage + 1 <=this.totalPages },

    seleccionarTrab: function(trab){
      this.portalCliente.estadosDocumentos.forEach(documento=>{
        if(documento.idDocumento == trab.documento.idDocumento){
          if(documento.estadoDocumento != trab.documento.estadoDocumento){
            trab.documento.respaldoEstado = trab.documento.estadoDocumento
            trab.documento.estadoDocumento = 2
            trab.documento.agregadoRechazo = true
          }
        }
      })
    },
    asyncFind: function(query){
      this.strFiltroTrabajadores = query
      this.currentPage = 1
      this.trabajadoresModal = []
      this.getTrabajadoresFiltrados("asyncFind")
    },
    getTrabajadores: function(vieneDe){
      
      this.isLoadingTrabajadores = true;
      //this.trabajadoresModal = []
      let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.portalCliente.selempresa.idEmpresa}/${(this.portalCliente.seltipodoc != null) ? this.portalCliente.seltipodoc.valor : 'null'}/${(this.portalCliente.mesSelected != null) ? this.portalCliente.mesSelected.valor : 'null'}/${(this.portalCliente.periodoSelected != null) ? this.portalCliente.periodoSelected.valor : 'null'}`


        if(this.rqstTrabs != null){
            this.rqstTrabs.cancel()
            this.rqstTrabs.msg = "cancelado";
            this.rqstTrabs = null;
        }
        const axiosSource = axios.CancelToken.source();
        this.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };
      
        axios({
          method: "POST",
          url: url,
          cancelToken: axiosSource.token,
          "data": {
              "paginacionTrabajadores": {
                  "strFiltro": this.strFiltroTrabajadores,
                  "actual": this.currentPage,
                  "tamanioPagina": 10
              }
          },
          headers: {
            Authorization: `${this.tokenLogin}`,
          },
        }).then((result) => {
          //if(this.currentPage == 1)
            //this.trabajadoresModal = []

            this.totalPages = Number((result.data.totalTrabajadores/10).toFixed(0))

            this.trabajadoresModal = this.trabajadoresModal.concat(result.data.trabajadores)
            this.currentPage++
          },
          (error) => {

          }
        )
        .finally(() => {
          this.isLoadingTrabajadores = false;
          this.rqstTrabs = null;
        });
    },
    getTrabajadoresFiltrados: function(vieneDe){
      
      this.isLoadingTrabajadores = true;
      //this.trabajadoresModal = []
      let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + this.portalCliente.selempresa.idEmpresa}/${(this.portalCliente.seltipodoc != null) ? this.portalCliente.seltipodoc.valor : 'null'}/${(this.portalCliente.mesSelected != null) ? this.portalCliente.mesSelected.valor : 'null'}/${(this.portalCliente.periodoSelected != null) ? this.portalCliente.periodoSelected.valor : 'null'}`

      

        if(this.rqstTrabs != null){
            this.rqstTrabs.cancel()
            this.rqstTrabs.msg = "cancelado";
            this.rqstTrabs = null;
        }
        const axiosSource = axios.CancelToken.source();
        this.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };
      
        axios({
          method: "POST",
          url: url,
          cancelToken: axiosSource.token,
          "data": {
              "paginacionTrabajadores": {
                  "strFiltro": this.strFiltroTrabajadores,
                  "actual": this.currentPage,
                  "tamanioPagina": 10
              }
          },
          headers: {
            Authorization: `${this.tokenLogin}`,
          },
        }).then((result) => {
          //if(this.currentPage == 1)
            //this.trabajadoresModal = []


            this.totalPages = Number((result.data.totalTrabajadores/10).toFixed(0))

            this.trabajadoresModal = this.trabajadoresModal.concat(result.data.trabajadores)
            this.currentPage++
          },
          (error) => {

          }
        )
        .finally(() => {
          this.isLoadingTrabajadores = false;
          this.rqstTrabs = null;
        });
    },


    vistaprevia: function (trabajador) {
      this.trabajadorprev = trabajador;
      this.contador = this.trabajadores.indexOf(this.trabajadorprev);
    },
    customTrabajador: function (trabajador) {
      return `${trabajador.rutTrabajador} – ${trabajador.nombreTrabajador} ${
        !trabajador.documento.hayDocumento ? "(Sin Documento)" : ""
      }`;
    },
    descargamodal: function () {
      var link = this.trabajadorprev.documento.urlDocumento;
      var fileLink = document.createElement("a");
      fileLink.href = link;
      fileLink.setAttribute("download", this.trabajadorprev.documento.nombreDocumento);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    anteriorsiguiente: function (derizq) {
      this.haydoc = true
      this.haydocsig = true
      var posTrabajadorActual = this.trabajadores.indexOf(this.trabajadorprev)
      if (derizq == 0) {
        var hayDocumento = false;
        var hayDocumentoAnt = false;

        for(var pos=posTrabajadorActual-1;pos>=0;pos--){
          
          if(hayDocumento){
            if(pos>=0){
              if(this.trabajadores[pos].documento.hayDocumento){
                hayDocumentoAnt = true;
              }
            }
          }else{
            if(this.trabajadores[pos].documento.hayDocumento){
              this.trabajadorprev = this.trabajadores[pos]
              hayDocumento = true;
            }
          }
          if(hayDocumentoAnt){
            break
          }
        }
        if(!hayDocumentoAnt){
          this.haydoc=false;
        }
      }
      if (derizq == 1) {
        
        var hayDocumento = false
        var hayDocumentoSig = false
        
        for(var pos=posTrabajadorActual+1;pos<this.trabajadores.length-1;pos++){

          if(hayDocumento){
            if(pos<=this.trabajadores.length-1)
              if(this.trabajadores[pos].documento.hayDocumento)
                hayDocumentoSig = true
          }else{
            if(this.trabajadores[pos].documento.hayDocumento){
              this.trabajadorprev = this.trabajadores[pos]
              
              hayDocumento = true;
            }
          }
          
          if(hayDocumentoSig)
            break
            
        
        }

        if(!hayDocumentoSig)
          this.haydocsig=false;
        
        
      }
    },

    cerrarModal: function () {
      this.portalCliente.prevue = false;
      if(this.portalCliente.estadosDocumentos.length>0){
        this.$notification.success("Tiene documento/s rechazado/s", {  timer: 5, position:"bottomRight", showCloseIcn:true});
      }
    },

    rechazarDocumentos: function () {
      this.trabajadorprev.documento.respaldoEstado = this.trabajadorprev.documento.estadoDocumento
      this.trabajadorprev.documento.estadoDocumento = 2
      this.trabajadorprev.documento.agregadoRechazo = true

      this.portalCliente.estadosDocumentos.push(this.trabajadorprev.documento);

      this.$notification.success("documento rechazado", {  timer: 5, position:"bottomRight", showCloseIcn:true});


    },
    quitarRechazoDocumento: function(){
      this.portalCliente.estadosDocumentos.forEach((documento, index)=>{
        if(documento.idDocumento == this.trabajadorprev.documento.idDocumento){
          this.trabajadorprev.documento.estadoDocumento = documento.respaldoEstado
          this.trabajadorprev.documento.agregadoRechazo = false
          this.portalCliente.estadosDocumentos.splice(index, 1)
        }
      })

    }
  },
};
</script>